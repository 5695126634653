export default {
  bom_type: {
    product: 1,
    kit: 2,
  },
  version_acceptance_percent: {
    lower: 95,
    upper: 105,
  },
  procurement_type: {
    supplied: 1,
    invoiced: 2,
    token: 3
  },
  bom_status: {
    inactive: 0,
    active: 1,
    draft: 2
  }
};
