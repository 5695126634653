<template>
    <header class="relative flex-shrink-0 flex h-14 bg-white">
        <button class="md:hidden px-4 text-neutral-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"  @click="toggleMenu">
            <span class="sr-only">Open sidebar</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
            </svg>
        </button>
        <div class="flex-1 px-4 flex justify-between">
            <div class="flex-1 flex"></div>
            <div class="ml-4 flex items-center md:ml-6">
                <button
                    @click="toggleGlobalSearch"
                    class="bg-white p-1.5 rounded text-neutral-600 hover:bg-neutral-50 hover:shadow-sm mr-2  ">
                    <span class="sr-only">Search</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </button>
                <Menu>
                    <MenuButton class="p-1.5 rounded text-neutral-600 hover:bg-neutral-50 focus:bg-primary-50 focus:text-primary-600 hover:shadow-sm mr-2">
                        <span class="sr-only">View notifications</span>
                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                        </svg>
                    </MenuButton>
                    <MenuItems class="z-20 w-80 bg-white rounded-lg shadow right-10 top-10 absolute">
                        <div class="inline-flex items-start justify-start px-4 py-2 text-lg font-semibold leading-7 text-neutral-800"> Notifications </div>
                        <div class="flex  items-start justify-between w-full px-4 py-2 border-b border-neutral-200">
                            <a href="#" class="text-sm text-primary-600">Mark all as read</a>
                            <a href="#" class="text-sm text-neutral-600">Remove all</a>
                        </div>
                        <div class="divide-y divide-neutral-200 h-96 overflow-x-auto scrollbar">
                            <MenuItem v-slot="{ active }" class="flex py-3 px-4 hover:bg-neutral-100">
                                <a :class='{ "bg-blue-500": active }'>
                                    <img class="inline-block h-6 w-6 rounded-full" src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="Rachel Barry">
                                    <div class="inline-flex flex-col space-y-1 items-start justify-start flex-1 ml-3">
                                        <div class="flex flex-col items-start justify-start w-full">
                                            <p class="w-full text-sm font-semibold  text-neutral-600">Rachel Barry</p>
                                            <p class="w-full text-sm  text-neutral-600">mentioned you on 195445 - 5-HTP 100 60 v-caps / Front Panel Regulatory</p>
                                        </div>
                                        <p class="w-full text-xs leading-none text-neutral-600">May 21, 2021 10:38 am</p>
                                    </div>
                                    <div class="flex space-x-1 items-start justify-start">
                                        <div class="flex items-start justify-start p-1">
                                            <div class="flex-1 w-3 h-3 bg-primary-600 rounded-full"></div>
                                        </div>
                                        <div class="flex items-start justify-start">
                                            <a href="javascript:;">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-neutral-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                            </svg>
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </MenuItem>
                            <MenuItem v-slot="{ active }" class="flex py-3 px-4 hover:bg-neutral-100">
                                <a :class='{ "bg-blue-500": active }'>
                                    <span class="inline-flex h-6 w-6 items-center justify-center rounded-full bg-red-500">
                                        <span class="text-xs font-medium leading-none text-white">LK</span>
                                    </span>
                                    <div class="inline-flex flex-col space-y-1 items-start justify-start flex-1 ml-3">
                                        <div class="flex flex-col items-start justify-start w-full">
                                            <p class="w-full text-sm font-semibold  text-neutral-600">Loden Kharl</p>
                                            <p class="w-full text-sm  text-neutral-600">mentioned you on 195510 - Green Up! Powder 300g / NPN</p>
                                        </div>
                                        <p class="w-full text-xs leading-none text-neutral-600">May 21, 2021 10:38 am</p>
                                    </div>
                                    <div class="flex space-x-1 items-start justify-start">
                                        <div class="flex items-start justify-start p-1">
                                            <div class="flex-1 w-3 h-3 bg-primary-600 rounded-full"></div>
                                        </div>
                                        <div class="flex items-center justify-start">
                                            <a href="javascript:;">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-neutral-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                            </svg>
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </MenuItem>
                            <MenuItem v-slot="{ active }" class="flex py-3 px-4 hover:bg-neutral-100">
                                <a :class='{ "bg-blue-500": active }'>
                                    <img class="inline-block h-6 w-6 rounded-full" src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="Rachel Barry">
                                    <div class="inline-flex flex-col space-y-1 items-start justify-start flex-1 ml-3">
                                        <div class="flex flex-col items-start justify-start w-full">
                                            <p class="w-full text-sm font-semibold  text-neutral-600">Rachel Barry</p>
                                            <p class="w-full text-sm  text-neutral-600">mentioned you on 195445 - 5-HTP 100 60 v-caps / Front Panel Regulatory</p>
                                        </div>
                                        <p class="w-full text-xs leading-none text-neutral-600">May 21, 2021 10:38 am</p>
                                    </div>
                                    <div class="flex space-x-1 items-start justify-start">
                                        <div class="flex items-start justify-start p-1">
                                            <div class="flex-1 w-3 h-3 bg-primary-600 rounded-full"></div>
                                        </div>
                                        <div class="flex items-start justify-start">
                                            <a href="javascript:;">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-neutral-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                            </svg>
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </MenuItem>
                            <MenuItem v-slot="{ active }" class="flex py-3 px-4 hover:bg-neutral-100">
                                <a :class='{ "bg-blue-500": active }'>
                                    <span class="inline-flex h-6 w-6 items-center justify-center rounded-full bg-red-500">
                                        <span class="text-xs font-medium leading-none text-white">LK</span>
                                    </span>
                                    <div class="inline-flex flex-col space-y-1 items-start justify-start flex-1 ml-3">
                                        <div class="flex flex-col items-start justify-start w-full">
                                            <p class="w-full text-sm font-semibold  text-neutral-600">Loden Kharl</p>
                                            <p class="w-full text-sm  text-neutral-600">mentioned you on 195510 - Green Up! Powder 300g / NPN</p>
                                        </div>
                                        <p class="w-full text-xs leading-none text-neutral-600">May 21, 2021 10:38 am</p>
                                    </div>
                                    <div class="flex space-x-1 items-start justify-start">
                                        <div class="flex items-start justify-start p-1">
                                            <div class="flex-1 w-3 h-3 bg-primary-600 rounded-full"></div>
                                        </div>
                                        <div class="flex items-center justify-start">
                                            <a href="javascript:;">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-neutral-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </MenuItem>
                            <MenuItem v-slot="{ active }" class="flex py-3 px-4 hover:bg-neutral-100">
                                <a :class='{ "bg-blue-500": active }'>
                                    <img class="inline-block h-6 w-6 rounded-full" src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" alt="Rachel Barry">
                                    <div class="inline-flex flex-col space-y-1 items-start justify-start flex-1 ml-3">
                                        <div class="flex flex-col items-start justify-start w-full">
                                            <p class="w-full text-sm font-semibold  text-neutral-600">Rachel Barry</p>
                                            <p class="w-full text-sm  text-neutral-600">mentioned you on 195445 - 5-HTP 100 60 v-caps / Front Panel Regulatory</p>
                                        </div>
                                        <p class="w-full text-xs leading-none text-neutral-600">May 21, 2021 10:38 am</p>
                                    </div>
                                    <div class="flex space-x-1 items-start justify-start">
                                        <div class="flex items-start justify-start p-1">
                                            <div class="flex-1 w-3 h-3 bg-primary-600 rounded-full"></div>
                                        </div>
                                        <div class="flex items-start justify-start">
                                            <a href="javascript:;">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-neutral-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                            </svg>
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </MenuItem>
                            <MenuItem v-slot="{ active }" class="flex py-3 px-4 hover:bg-neutral-100">
                                <a :class='{ "bg-blue-500": active }'>
                                    <span class="inline-flex h-6 w-6 items-center justify-center rounded-full bg-red-500">
                                        <span class="text-xs font-medium leading-none text-white">LK</span>
                                    </span>
                                    <div class="inline-flex flex-col space-y-1 items-start justify-start flex-1 ml-3">
                                        <div class="flex flex-col items-start justify-start w-full">
                                            <p class="w-full text-sm font-semibold  text-neutral-600">Loden Kharl</p>
                                            <p class="w-full text-sm  text-neutral-600">mentioned you on 195510 - Green Up! Powder 300g / NPN</p>
                                        </div>
                                        <p class="w-full text-xs leading-none text-neutral-600">May 21, 2021 10:38 am</p>
                                    </div>
                                    <div class="flex space-x-1 items-start justify-start">
                                        <div class="flex items-start justify-start p-1">
                                            <div class="flex-1 w-3 h-3 bg-primary-600 rounded-full"></div>
                                        </div>
                                        <div class="flex items-center justify-start">
                                            <a href="javascript:;">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-neutral-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                                            </svg>
                                            </a>
                                        </div>
                                    </div>
                                </a>
                            </MenuItem>
                        </div>
                    </MenuItems>
                </Menu>
                <!-- Notification -->
                <div class="inset-y-0 right-0 pr-3 flex items-center h-10">
                    <Menu>
                        <div class="relative inline-block text-left">
                            <MenuButton id="headlessui-menu-button-3" type="button" aria-expanded="true" aria-haspopup="true"
                                class="w-9 h-9 rounded-full border-primary-500">
                                <span v-if="selectedWarehouse" class="p-1 px-2 inline-flex items-center rounded" :class="selectedWarehouse.color">{{ selectedWarehouse.abbreviation }}</span>
                            </MenuButton>
                            <MenuItems
                                class="origin-top-right absolute z-20 right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                >
                                <div class="py-1">
                                    <MenuItem v-for="warehouse in userData.warehouseOptions" v-slot="{ active }">
                                        <a 
                                        @click="changeWarehouse(warehouse.id)"
                                        class="group flex items-center px-3 py-2 text-sm cursor-pointer">
                                            <div class="p-1 px-2 inline-flex items-center rounded" :class="warehouse.color">
                                                <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_4039_881182)">
                                                        <path d="M4 27.9999V10.6666L16 5.33325L28 10.6666V27.9999" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M17.3333 17.3333H22.6666V27.9999H9.33331V19.9999H17.3333" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                        <path d="M17.3333 28.0001V16.0001C17.3333 15.6465 17.1929 15.3073 16.9428 15.0573C16.6928 14.8072 16.3536 14.6667 16 14.6667H13.3333C12.9797 14.6667 12.6406 14.8072 12.3905 15.0573C12.1405 15.3073 12 15.6465 12 16.0001V20.0001" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_4039_881182">
                                                            <rect width="32" height="32" fill="white"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                                <span class=" ml-2">{{ warehouse.label }}</span> 
                                            </div>
                                        </a>
                                    </MenuItem>
                                </div>
                            </MenuItems>
                        </div>
                    </Menu>
                </div>
                <div class="inset-y-0 right-0 pr-1 flex items-center h-10">
                    <Menu>
                        <div class="relative inline-block text-left">
                        <MenuButton id="headlessui-menu-button-3" type="button" aria-expanded="true" aria-haspopup="true"
                            class="w-9 h-9 rounded-full border-primary-500">
                            <UserAvatarComponent />
                        </MenuButton>
                        <MenuItems
                            class="origin-top-right absolute z-20 right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                            >
                            <div class="p-3 bg-blue-50">
                                <p class="text-sm">
                                    {{userData ? userData.first_name+' '+userData.last_name : ''}}
                                </p>
                                <p class="text-sm font-medium text-gray-900 truncate">
                                    {{userData ? userData.department : ''}}
                                </p>
                            </div>
                            <div class="py-1">
                                <MenuItem v-slot="{ active }">
                                    <a 
                                        :href="profileUrl"
                                        :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-800', 'group flex items-center px-3 py-2 text-sm']">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 h-5 w-5 text-gray-700 group-hover:text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                        </svg>
                                        Your Profile
                                    </a>
                                </MenuItem>
                                <MenuItem v-slot="{ active }">
                                    <a 
                                        :href="switchUrl"
                                        :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-800', 'group flex items-center px-3 py-2 text-sm']">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 h-5 w-5 text-gray-700 group-hover:text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"></path>
                                        </svg>
                                        {{ switchUser }}
                                    </a>
                                </MenuItem>
                                <MenuItem v-slot="{ active }">
                                    <router-link to="/logout" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-800', 'group flex items-center px-3 py-2 text-sm']">
                                        <svg xmlns="http://www.w3.org/2000/svg" class="mr-3 h-5 w-5 text-gray-700 group-hover:text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                                        </svg>
                                        Sign out
                                    </router-link>
                                </MenuItem>
                            </div>
                        </MenuItems>
                        </div>
                    </Menu>
                </div>
            </div>
        </div>
        <div v-if="loading" :class="{'absolute z-20 h-screen bg-trasperent h-screen top-0 left-0' : loading}" class="w-full py-8 px-4 flex flex-col justify-center items-center">
            <svg class="animate-spin -ml-1 mr-3 h-12 w-12 text-blue-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <div class="text-gray-600 text-lg mt-2 text-center">Logging out please wait...</div>
        </div>
    </header>
    <GlobalSearchComponent @closeSearch="toggleGlobalSearch" v-if="showGlobalSearch" />
</template>

<script>
    import UserAvatarComponent from '@/components/UserAvatarComponent.vue'
    import commonMixin from '../../mixin'
    import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
    import GlobalSearchComponent from '@/components/GlobalSearchComponent.vue';
    import axios from 'axios';
    export default {
        components: {
            Menu, MenuButton, MenuItems, MenuItem, UserAvatarComponent, GlobalSearchComponent
        },
        data() {
            return {
                loading: false,
                showGlobalSearch: false
            }
        },
        mixins: [commonMixin],
        mounted() {
            //
        },
        methods: {
            logoutUser() {
                this.loading = true;
                this.$store.dispatch('logoutUser');
            },
            toggleMenu() {
                this.$emit('toggleMobileMenu')
            },
            toggleGlobalSearch() {
                this.showGlobalSearch = !this.showGlobalSearch
            },
            changeWarehouse(warehouseId) {
                axios({
                    method: 'put',
                    url: `users/${this.userData.id}`,
                    data: {
                        kn_warehouse_id: warehouseId,
                        warehouse_confirmation_flag: 0
                    }
                }).then(res => {
                    if (res.status == 200) {
                        this.$store.dispatch('checkAuth')
                    }
                }).catch(err => {
                    if (err.response.status == 400) {
                        this.errors = err.response.data.detail;
                    }
                });
            },
        },
        computed: {
            getAuthUser() {
                return this.$store.getters.authUser;
            },
            selectedWarehouse(){
                return this.userData.warehouseOptions.find(warehouse => warehouse.id == this.userData.kn_warehouse_id)
            },
            profileUrl() {
                return process.env.VUE_APP_AUTH_URL + '/member/' + this.userData.id +'?return_url=' + process.env.VUE_APP_URL
            },
            switchUrl() {
                return `${this.profileUrl}&tab=switch-user`;
            },
            switchUser() {
                const switchUser = localStorage.getItem('switch_user');
                return switchUser && switchUser === '1' ? 'Switch Back' : 'Switch User';
            }
        }
    };
</script>