export default {
  tasks: {
    pre_receive: 1,
    initial_inspection: 2,
    verification_questions: 3,
    count: 4,
    inital_samples: 5,
    print_labels: 6,
    transfer: 7,
    operation_signoff: 8,
    qa_signoff: 9,
    vp_operation_signoff: 10,
    kit_pick: 11,
    kit_print_upc_stickers: 12,
    kit_assemble: 13,
    kit_print_label: 14,
    kit_transfer: 15,
    kit_operation_signoff: 16,
    raw_count: 17,
    raw_verification_images: 18,
    raw_print_labels: 19,
    raw_transfer: 20,
    raw_operation_signoff: 21,
    marketing_count: 22,
    marketing_verification_images: 23,
    marketing_print_labels: 24,
    marketing_transfer: 25,
    marketing_signoff: 26,
    kit_cs_signoff: 27,
  },
  qa_sample_guide: {
    0: {
      label: "1st SP(new product)",
      count: 8,
    },
    1: {
      label: "2-50",
      count: 2,
      min: 2,
      max: 50,
    },
    50: {
      label: "51-500",
      count: 3,
      min: 51,
      max: 500,
    },
    500: {
      label: "501-35000",
      count: 5,
      min: 501,
      max: 35000,
    },
    35000: {
      label: "35000+",
      count: 8,
      min: 35000,
    },
  },
  shipment_status: {
    pending: 1,
    in_progress: 2,
    released: 3,
    cancelled: 4,
    rejected: 5,
  },
  shipment_skid_box_type: {
    full_layers: 0,
    partial_layer: 1,
    open_box: 2,
  },
  kit_pick_status: {
    pending: 0,
    in_progress: 1,
    done: 2,
  },
};