<template>
    <img v-if="getAvatarPath" class="inline-block h-8 w-8 rounded-full" :src="getAvatarPath" alt="user avatar">
    <span v-else-if="userData.first_name"
        :title="userTitle"
        :style="`background-color:${ userColor };`"
        class="inline-flex items-center justify-center h-8 w-8 rounded-full">
        <span class="text-sm font-medium leading-none text-white">
            {{getInitials(userTitle)}}
        </span>
    </span>
</template>
<script>
import commonMixin from '@/mixin'
export default {
    props: ['photo', 'title', 'color'],
    data() {
        return {
            userPhoto: '',
            userTitle: ' ',
            userColor: ''
        }
    },
    mounted() {
        if (this.photo || this.title) {
            this.userPhoto = this.photo;
            this.userTitle = this.title;
            this.userColor = this.color
        } else if(this.userData) {
            this.userPhoto = this.userData.photo;
            this.userTitle = `${this.userData.first_name} ${this.userData.last_name}`;
            this.userColor = this.userData.color
        }
    },
    mixins: [commonMixin],
    computed: {
        getAvatarPath() {
            if (this.userPhoto) {
                return this.getPhotoPath(process.env.VUE_APP_PROFILE_PHOTO_PATH, this.userPhoto)
            }
            return null;
        },
    }
}
</script>