export default {
  part_type: {
    product: 1,
    kit: 2,
    raw: 3,
    marketing: 4,
    test_token: 5,
    rtoken: 6,
    other: 7
  },
  label_classes: [
    "label",
    "tube",
    "film",
    "carton"
  ],
  label_class_ids: [
    +process.env.VUE_APP_RAW_LABEL_PART_CLASS_ID,
    +process.env.VUE_APP_RAW_TUBE_PART_CLASS_ID,
    +process.env.VUE_APP_RAW_FILM_PART_CLASS_ID,
    +process.env.VUE_APP_RAW_CARTON_PART_CLASS_ID
  ],
  part_class: {
    "display": +process.env.VUE_APP_KIT_DISPLAY_PART_CLASS_ID,
    "gift_basket": +process.env.VUE_APP_KIT_GIFT_BASKET_PART_CLASS_ID,
    "carton": +process.env.VUE_APP_RAW_CARTON_PART_CLASS_ID,
    "film": +process.env.VUE_APP_RAW_FILM_PART_CLASS_ID,
    "label": +process.env.VUE_APP_RAW_LABEL_PART_CLASS_ID,
    "tube": +process.env.VUE_APP_RAW_TUBE_PART_CLASS_ID,
    "product": +process.env.VUE_APP_PRODUCT_PART_CLASS_ID,
    "bottle": +process.env.VUE_APP_RAW_BOTTLE_PART_CLASS_ID,
    "cap": +process.env.VUE_APP_RAW_CAP_PART_CLASS_ID,
    "master_case_box": +process.env.VUE_APP_RAW_MASTERCASEBOX_PART_CLASS_ID,
    "partition": +process.env.VUE_APP_RAW_PARTITION_PART_CLASS_ID,
    "bulk": +process.env.VUE_APP_RAW_BULK_PART_CLASS_ID,
  },
  lot_status: {
    "quarintine": 1,
    "released": 2,
    "inuse": 3,
    "scrapped": 4,
    "depleted": 5,
    "void": 6,
    "expired": 9,
  },
  warehouse_color: {
    "1": '#60A5FA',
    "3": '#F87171'
  },
  purchase_order_category: {
    "existingProduct": 1,
    "newProduct": 2,
    "labelClasses": 3,
    "otherClasses": 4,
    "bulkClass": 5,
    "marketing":8
  }
};
